import axios from "axios"
import {defineStore} from "pinia"
import {ref} from "vue"

import {useLoaderStore} from "@shared/stores/LoaderStore"
import {useWalletStore} from "@shared/stores/WalletStore"

export const useWageringStore = defineStore('WageringStore', () => {
    const loaderStore = useLoaderStore()
    const walletStore = useWalletStore()

    const isWageringModalVisible = ref(false)
    const wagering = ref(null)

    const bonusWallet = ref(null)
    const normalWallet = ref(null)

    const setWageringModalVisibility = async (visibility) => {
        if (visibility) {
            await fetchWagering()
        }
        isWageringModalVisible.value = visibility
    }

    const fetchWagering = async () => {
        loaderStore.increment()
        try {
            const { data } = await axios.get('/api/wagering')
            const { wallets, ...rest } = data

            const bonus = wallets.find(wallet => wallet.gateway === 'bonus')
            bonusWallet.value = {
                ...bonus,
                balance: parseFloat(bonus.balance) || 0
            }

            const normal = wallets.find(wallet => wallet.gateway === 'callpay')
            normalWallet.value = {
                ...normal,
                balance: parseFloat(normal.balance) || 0
            }

            wagering.value = rest
        } finally {
            loaderStore.decrement()
        }

    }

    const optOutBonusWagering = async () => {
        loaderStore.increment()
        try {
            await axios.put('/api/bonusOptOut')
            await fetchWagering()

            walletStore.fetchWallet()
        } finally {
            loaderStore.decrement()
        }
    }

    return {
        isWageringModalVisible,
        bonusWallet,
        normalWallet,
        optOutBonusWagering,
        wagering,
        fetchWagering,
        setWageringModalVisibility,
    }
})
