import axios from 'axios'

import {useAccountStore} from "@shared/stores/AccountStore"
import {useAuthStore} from "@shared/stores/AuthStore"
import { useLoaderStore } from "@shared/stores/LoaderStore"

export function useApi() {
    const accountStore = useAccountStore()
    const authStore = useAuthStore()
    const loaderStore = useLoaderStore()

    const apiDelete = async (endpoint, payload, options = {isSilent: false}) => await apiCall('delete', {endpoint, payload, options})
    const apiGet = async (endpoint, options = {isSilent: false}) =>  await apiCall('get', {endpoint, options})
    const apiPost = async (endpoint, payload, options = {isSilent: false}) => await apiCall('post', {endpoint, payload, options})
    const apiPut = async (endpoint, payload, options = {isSilent: false}) => await apiCall('put', {endpoint, payload, options})

    const apiCall = async (method, {endpoint, payload, options = { isSilent: false }}) => {
        const { isSilent } = options

        isSilent || loaderStore.increment()
        try {
            const response = await axios[method](endpoint, payload)
            if (response.data.error) {
                throw response.data.error
            }
            return response
        } catch ({ response }) {

            if(response.status === 401) {
                accountStore.signOut({
                    withReload: false
                })
                authStore.setAuthModalVisibility(true)
                return
            }

            throw response.data.error || response.data.message || 'An error occurred'
        } finally {
            isSilent || loaderStore.decrement()
        }
    }

    return {
        apiDelete,
        apiGet,
        apiPost,
        apiPut,
    }
}
