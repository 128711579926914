import {defineStore} from "pinia"
import {computed, ref} from "vue"

import {useApi} from "@shared/composables/useApi"
import useSocket from "@shared/composables/useSocket"
import {useLoyaltyChestStore} from "@shared/stores/LoyaltyChestStore"
import {useNotificationsStore} from "@shared/stores/NotificationsStore"
import {useWalletStore} from "@shared/stores/WalletStore"

export const useAccountStore = defineStore('AccountStore', () => {
    const { apiGet, apiPost } = useApi()
    const { connect } = useSocket()

    const loyaltyChestStore = useLoyaltyChestStore()
    const notificationsStore = useNotificationsStore()
    const walletStore = useWalletStore()

    const user = ref({})
    const isUserFetched = ref(false)
    const isProfileModalOpen = ref(false)

    const isAuthenticated = computed(() => user.value && user.value.id)

    const fetchUser = async (forceRefresh = false) => {
        if(localStorage.getItem('play_live_session_token')) {
            if(forceRefresh || !isUserFetched.value) {
                const {data} = await apiGet('/api/account')

                user.value = data
                isUserFetched.value = true

                notificationsStore.fetchNotifications()
                walletStore.fetchWallet()
            }

            connect(`user.${user.value.id}`, (data, name) => {
                if(name === 'loyaltyProgress') {
                    console.log('loyaltyProgress', data)
                    loyaltyChestStore.setLoyaltyProgress({
                        progress: data.progress,
                        newDiamond: data.newDiamond,
                        newChest: data.newChest
                    })
                }
            })
        }
    }

    const patchUserProfile = async (payload) => {
        try {
            const { data: responseData } = await apiPost('/api/updateProfile', payload)
            if(responseData.error) {
                throw new Error(responseData.error)
            }
            isProfileModalOpen.value = false
            await fetchUser()
            return responseData
        } catch (error) {
            throw new Error(error)
        }
    }

    const signOut = ({ withReload = true } = {}) => {
        localStorage.removeItem('play_live_session_token')
        user.value = {}
        if(withReload) {
            window.location.reload()
        }
    }

    return {
        user,
        isAuthenticated,
        isProfileModalOpen,
        closeProfileModal: () => isProfileModalOpen.value = false,
        openProfileModal: () => isProfileModalOpen.value = true,
        fetchUser,
        patchUserProfile,
        signOut
    }
}, {
    persist: [
        {
            paths: [
                'user'
            ]
        }
    ]
})
