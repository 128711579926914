<script setup>
import {Carousel, Slide} from "vue3-carousel";
import {computed} from "vue";
import {useProvidersStore} from "@shared/stores/ProvidersStore";

const providersStore = useProvidersStore()
const providers = computed(() => providersStore.providers)

</script>

<template>
    <div id="gameproviders">
        <Carousel
            :items-to-show="7"
            :autoplay="500"
            :transition="2000"
            :pauseAutoplayOnHover="true"
            :wrapAround="true"
        >
            <template v-for="provider in providers" :key="provider.product">
                <Slide>
                    <img
                        class="imgprovider"
                        :src="provider.image"
                        :alt="provider.product"
                        loading="lazy"
                    />
                </Slide>
            </template>

        </Carousel>

        <div class="edge edge-l"></div>
        <div class="edge edge-r"></div>
    </div>
</template>

<style scoped>
    .carousel {
        width: 100%;
    }
    .carousel__item {
        min-height: 200px;
        width: 100%;
        background-color: var(--vc-clr-primary);
        color: var(--vc-clr-white);
        font-size: 20px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .carousel__slide {
        min-height: 50px;
    }

    .carousel__prev,
    .carousel__next {
        box-sizing: content-box;
        border: 5px solid white;
    }
</style>
