import {defineStore} from "pinia"
import {computed, ref} from "vue"

import {useApi} from "@shared/composables/useApi"
import {useAccountStore} from "@shared/stores/AccountStore"

export const useGameStore = defineStore('GameStore', () => {

    const {apiGet, apiPut} = useApi()

    const accountStore = useAccountStore()

    const isAllGamesFetched = ref(false)
    const isGamesFetched = ref(false)

    const favourites = ref([])
    const allGames = ref([])
    const games = ref({
        mostPopular: [
            {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
        ],
        Lives: [
            {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
        ],
        topRated: [
            {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
        ]
    })
    const subGroups = ref([])

    const filterGamesByCategory = (subCategory) => {
        return allGames.value.filter(game => game.category.toLowerCase().includes(subCategory.toLowerCase()))
    }

    const getGamesByCategory = ({
        category,
        subCategory = null,
        product = null,
        limit = null,
        offset = 0,
        filter = '',
        sortBy = '', // alphasc, alphdsc, newestf
    }) => {
        let categoryGames = []

        switch (category) {
            case 'all':
                categoryGames = allGames.value
                break
            case 'platform-desktop':
                categoryGames = allGames.value.filter(game => game.platforms.includes('desktop'))
                break
            case 'platform-mobile':
                categoryGames = allGames.value.filter(game => game.platforms.includes('mobile'))
                break
            case 'bonus-play':
                categoryGames = allGames.value.filter(game => game.isBonusPlay)
                break
            case 'freebet':
                categoryGames = allGames.value.filter(game => game.isFreebet)
                break
            case 'category':
                subCategory = ['live-roulette', 'live-blackjack'].includes(subCategory)
                    ? subCategory.replace('live-', '')
                    : subCategory

                subCategory = subCategory === 'video-bingo' ? 'bingo' : subCategory

                categoryGames = subCategory === 'table-games'
                    ? filterGamesByCategory('baccarat')
                        .concat(
                            filterGamesByCategory('blackjack'),
                            filterGamesByCategory('poker'),
                            filterGamesByCategory('roulette'),
                            filterGamesByCategory('sic')
                        )
                    : filterGamesByCategory(subCategory)
                break
            case 'favourites':
                categoryGames = favourites.value.length ? allGames.value.filter(game => favourites.value.includes(game.id)) : []
                break
            case 'newGames':
                categoryGames = [...allGames.value]
                    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at) || b.id - a.id)
                    .slice(0, 50)
                break
            default:
                categoryGames = games.value[category] || []
        }

        switch (sortBy) {
            case 'alphasc':
                categoryGames.sort((a, b) => a.name.localeCompare(b.name));
                break;

            case 'alphdsc':
                categoryGames.sort((a, b) => b.name.localeCompare(a.name));
                break;

            case 'newestf':
                categoryGames.sort((a, b) => new Date(b.created_at) - new Date(a.created_at) || b.id - a.id)
                break;

            default: break;
        }

        if (filter) {
            categoryGames = categoryGames.filter(game => game.name.toLowerCase().includes(filter.toLowerCase()))
        }

        if (product) {
            categoryGames = categoryGames.filter(game => game.product === product)
        }

        const count = {
            all: categoryGames.length,
        }

        if (offset) {
            categoryGames = categoryGames.slice(offset + 1)
        }

        if (limit) {
            categoryGames = categoryGames.slice(0, limit)
        }

        if(!accountStore.isAuthenticated) {
            categoryGames = categoryGames.filter(game => game.product !== 'Pragmatic Play')
        }

        return {
            count,
            items: categoryGames
        }
    }

    const getGameByCode = computed(() => (gameCode) =>
        allGames.value.find(game => game.game_code === gameCode
        )
    )

    const getGameBySlug = computed(() => (gameSlug) =>
        allGames.value.find(game => game.game_url === gameSlug
        )
    )

    const setIsGameFavourite = async (gameId) => {
        try {
            await apiPut(`/api/favourites/${gameId}`)
            fetchFavourites()
        } catch (e) {
            console.warn(e)
        }
    }

    const fetchFavourites = async () => {
        try {
            if(accountStore.isAuthenticated) {
                const {data} = await apiGet('/api/favourites/short', {isSilent: true})
                favourites.value = data
            }
        } catch (e) {
            console.warn(e)
        }
    }

    const fetchAllGames = async () => {
        if (!isAllGamesFetched.value) {
            try {
                const {data} = await apiGet('/api/allgames', {isSilent: true})
                allGames.value = data
                isAllGamesFetched.value = true
                setSubGroups()
            } catch (e) {
                console.warn(e)
            }
        }
    }

    const fetchGames = async (refresh = false, options = {}) => {
        if (refresh || !isGamesFetched.value) {
            try {
                const {data} = await apiGet('/api/games', options)

                isGamesFetched.value = true
                games.value = data

                fetchFavourites()
            } catch (e) {
                console.warn(e)
            }
        }
    }

    const setSubGroups = () => {
        const uniqueSubGroups = new Map()

        allGames.value
            .filter(game => game.sub_groups && game.sub_groups.length > 0)
            .flatMap(game => JSON.parse(game.sub_groups))
            .forEach(subGroup => uniqueSubGroups.set(subGroup.id, subGroup))

        subGroups.value = Array.from(uniqueSubGroups.values())
    }

    return {
        allGames,
        games,
        favourites,
        subGroups,
        getGameByCode,
        getGameBySlug,
        getGamesByCategory,
        fetchAllGames,
        fetchGames,
        fetchFavourites,
        setIsGameFavourite,
    }
}, {
    persist: [
        {
            paths: [
                'allGames',
                'games',
                'favourites',
                'subGroups',
            ]
        }
    ]
})
