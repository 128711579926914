import {defineStore} from "pinia"
import {ref} from "vue"

const DEFAULT_AFFILIATE = 'organic'

export const useAffiliateStore = defineStore('AffiliateStore', () => {

    const affiliate = ref(DEFAULT_AFFILIATE)
    const trackingId = ref()

    const setAffiliate = (_affiliate) => {
        affiliate.value = _affiliate || affiliate.value || DEFAULT_AFFILIATE
    }

    const setTrackingId = (_trackingId) => {
        trackingId.value = _trackingId
    }

    return {
        affiliate,
        trackingId,
        setAffiliate,
        setTrackingId,
    }
}, {
    persist: [
        {
            paths: [
                'affiliate', // tracking code or id,
                'trackingId',
            ]
        }
    ]
})
