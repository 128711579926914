<script setup>
import {defineAsyncComponent, onMounted} from "vue"

const GameList = defineAsyncComponent(() => import("@shared/components/molecules/GameList.vue"))
const GamesNavigation = defineAsyncComponent(() => import("@shared/components/molecules/GamesNavigation.vue"))

import PromotionsBanner from "@shared/components/molecules/PromotionsBanner.vue"
import {useGameStore} from "@shared/stores/GameStore"

const gameStore = useGameStore()

onMounted(function() {
    gameStore.fetchGames()

})
</script>

<template>
    <div id="gamesPage">

        <GamesNavigation />

        <div id="homePage" style="background-color: unset;">
            <PromotionsBanner section="homepage" />
        </div>

        <div id="webplay">
            <div class="wrapper">
                <div class="gamesblock">

                    <h2>TOP RATED</h2>
                    <div class="gamesblock">
                        <GameList
                            group="topRated"
                            :url="{name: 'webplay.top-games'}"
                        />
                    </div>

                    <h2>MOST POPULAR</h2>
                    <div class="gamesblock">
                        <GameList
                            group="mostPopular"
                            :url="{name: 'webplay.most-popular'}"
                            :display-amounts="{xl: 15, md: 12, sm: 6}"
                        />
                    </div>

                    <h2>LIVE CASINO</h2>
                    <div class="gamesblock">
                        <GameList
                            group="Lives"
                            :url="{name: 'webplay.live-casino'}"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
