import axios from "axios"
import {defineStore} from "pinia"
import {computed, ref} from "vue"

const TYPE_ALLOCATION = "allocation"
const TYPE_BONUS_TRANSFER_OPT_OUT = "bonus_transfer_opt_out"
const TYPE_BONUS_WAGERING_COMPLETED = "bonus_wagering_completed"
const TYPE_CASHBACK = "cashback"
const TYPE_CASH_DROP_FRIDAY = 'cash_drop_friday'
const TYPE_CREATED_BY_USER = "created_by_user"
const TYPE_JACKPOT_WIN = "jackpot_win"
const TYPE_LOYALTY_CHEST_UNLOCKED = 'loyalty_chest_unlocked'
const TYPE_LOYALTY_ALLOCATION = 'loyalty_allocation'
const TYPE_LOYALTY_TIER_UNLOCKED = 'loyalty_tier_unlocked'
const TYPE_TOURNAMENT_PRIZE = 'tournament_prize'
const TYPE_TOURNAMENT_LEADERBOARD_PRIZE = 'tournament_leaderboard_prize'
const TYPE_WELCOME_MESSAGE = 'welcome_message'
const TYPE_WITHDRAWAL_ROLLBACK = 'withdrawal_rollback'
const TYPE_WITHDRAWAL_SUCCESS = "withdrawal_success"

export const useNotificationsStore = defineStore('NotificationStore', () => {

    const alert = ref('')

    const showAlert = (message) => {
        alert.value = message
    }

    const isNotificationsModalVisible = ref(false)
    const notifications = ref([])

    const getUnreadNotificationsCount = computed(() => notifications.value
        .filter((notification) => !notification.read_at)
        .length
    )

    const getNotificationsByType = computed(() => type => {
        let filter = [
            TYPE_ALLOCATION,
            TYPE_BONUS_TRANSFER_OPT_OUT,
            TYPE_BONUS_WAGERING_COMPLETED,
            TYPE_CASHBACK,
            TYPE_CASH_DROP_FRIDAY,
            TYPE_CREATED_BY_USER,
            TYPE_JACKPOT_WIN,
            TYPE_LOYALTY_CHEST_UNLOCKED,
            TYPE_LOYALTY_ALLOCATION,
            TYPE_LOYALTY_TIER_UNLOCKED,
            TYPE_TOURNAMENT_PRIZE,
            TYPE_TOURNAMENT_LEADERBOARD_PRIZE,
            TYPE_WELCOME_MESSAGE,
            TYPE_WITHDRAWAL_ROLLBACK,
            TYPE_WITHDRAWAL_SUCCESS
        ]

        switch (type) {
            case 'allocation':
                filter = [TYPE_ALLOCATION, TYPE_LOYALTY_ALLOCATION]
                break
            case 'cashback':
                filter =  [TYPE_CASHBACK]
                break
            case 'withdraw':
                filter =  [TYPE_WITHDRAWAL_SUCCESS, TYPE_WITHDRAWAL_ROLLBACK]
                break
            case 'win':
                filter =  [TYPE_CASH_DROP_FRIDAY, TYPE_JACKPOT_WIN, TYPE_TOURNAMENT_PRIZE, TYPE_TOURNAMENT_LEADERBOARD_PRIZE]
                break
            case 'other':
                filter =  [
                    TYPE_BONUS_TRANSFER_OPT_OUT,
                    TYPE_BONUS_WAGERING_COMPLETED,
                    TYPE_CREATED_BY_USER,
                    TYPE_LOYALTY_CHEST_UNLOCKED,
                    TYPE_LOYALTY_TIER_UNLOCKED,
                    TYPE_WELCOME_MESSAGE
                ]
                break
        }

        return notifications.value.filter((notification) => filter.includes(notification.type))
    })

    const fetchNotifications = (setRead = false) => {
        axios.get(`/api/userNotifications/${setRead}`)
            .then(({data}) => {
                notifications.value = data.map((notification) => {
                    return {
                        id: notification.id,
                        created_at: notification.created_at,
                        read_at: notification.read_at,
                        message: notification.user_notification.message,
                        subject: notification.user_notification.subject,
                        status: notification.user_notification.status,
                        type: notification.user_notification.type
                    }
                })
            })
    }

    const setNotificationsModalVisibility = (value) => {
        if(value) {
            fetchNotifications(true)
        }
        isNotificationsModalVisible.value = value
    }

    return {
        isNotificationsModalVisible,
        notifications,
        getNotificationsByType,
        getUnreadNotificationsCount,
        fetchNotifications,
        setNotificationsModalVisibility,
        alert,
        showAlert,
        hideAlert: () => alert.value = '',
    }
}, {
    persist: [
        {
            paths: [
                'notifications'
            ]
        }
    ]
})
