import axios from "axios"
import {defineStore} from "pinia"
import {ref} from "vue"

import {useApi} from "@shared/composables/useApi"
import {useLoaderStore} from "@shared/stores/LoaderStore";

export const useAuthStore = defineStore('AuthStore', () => {

    const loaderStore = useLoaderStore()
    const { apiPost } = useApi()

    const isAuthModalVisible = ref(false)
    const currentAuthModalMode = ref('login')

    const postAuth = async (url, payload) => {
        try {
            const { data } = await apiPost(url, payload)
            if(data.success?.token || data.token) {
                const token = data.success?.token || data.token
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                localStorage.setItem('play_live_session_token', token)
                return token
            }
            throw new Error(data.error || 'Unknown error')
        } catch (error) {
            throw new Error(error || 'Unknown error')
        }
    }

    const postLogin = (payload) => postAuth('/api/login', payload)
    const postRegistration = (payload) => postAuth('/api/register', payload)

    const postPasswordReset = async (email) => {
        return await apiPost('/api/forgotPassword', {email})
    }
    const postConfirmPasswordReset = async (pyload) => {
        const {data} = await apiPost('/api/resetPassword', pyload)

        if(data.success) {
            const token = data.success?.token || data.token
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            localStorage.setItem('play_live_session_token', token)
        }

        return true
    }

    return {
        isAuthModalVisible,
        currentAuthModalMode,
        postLogin,
        postConfirmPasswordReset,
        postPasswordReset,
        postRegistration,
        setAuthModalMode: (mode) => currentAuthModalMode.value = mode,
        setAuthModalVisibility: (isVisible, mode = 'login') => {

            const { sso } = window
            if (sso?.isForced) {
                loaderStore.increment() // show loader
                setTimeout(() => {
                    loaderStore.decrement() // hide loader after 1 second
                }, 1000)

                window.location.href = mode === 'login' ? sso.url.login : sso.url.signup
            } else {
                isAuthModalVisible.value = isVisible
                currentAuthModalMode.value = mode
            }
        },
    }
})
