import {defineStore} from "pinia"
import {ref} from "vue"

export const useLoaderStore = defineStore('LoaderStore', () => {
    const loaderCount = ref(0)

    const increment = () => loaderCount.value++
    const decrement = () => loaderCount.value > 0 && loaderCount.value--
    const stop = () => loaderCount.value = 0

    return {
        loaderCount,
        increment,
        decrement,
        stop
    }
})
