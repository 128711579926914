<script setup>
import {computed} from "vue"

import ResponsiveImage from "@shared/components/atoms/ResponsiveImage.vue"
import {useAccountStore} from "@shared/stores/AccountStore"
import {useGamePlayStore} from "@shared/stores/GamePlayStore"
import {useGameStore} from "@shared/stores/GameStore"

const accountStore = useAccountStore()
const gameStore = useGameStore()
const gamePlayStore = useGamePlayStore()

const props = defineProps({
    game: {
        type: Object,
        required: true
    }
})

const isGameFavourite = computed(() =>
    gameStore.favourites.includes(parseInt(props.game.id))
)

const isTryModeEnabled = computed(() =>
    props.game
    && props.game?.product?.toLowerCase() !== "pgsoft"
    && !props.game?.category?.toLowerCase().includes('live')
)

</script>

<template>
    <div class="gameitem">
        <div class="gamepreview">
            <responsive-image
                :url="game.url_thumb"
                :sizes="[
                    {size: 150, mediaQuery: '(max-width: 375px)'},
                    {size: 200, mediaQuery: '(min-width: 375px) and (max-width: 475px)'},
                    {size: 380, mediaQuery: '(min-width: 476px)'},
                ]"
                :alt="game.name"
                class="gamethumb"
            />
            <div class="overlay">
                <div class="gameIcons">
                    <div
                        v-if="accountStore.isAuthenticated"
                        :class="{
                            'btnStar fa': true,
                            'fa-star-o': !isGameFavourite,
                            'fa-star': isGameFavourite,
                        }"
                        @click="() => gameStore.setIsGameFavourite(game.id, isGameFavourite)"
                    />
                    <router-link
                        v-if="game.game_url"
                        class="btnInfo"
                        style="color: #fff;"
                        :to="{name: 'game-detail', params: {gameCode: game.game_url}}"
                    >
                        <span class="fa fa-info-circle"/>
                    </router-link>
                </div>
                <div class="divButtons">
                    <button class="btnPlay" @click="() => gamePlayStore.playGame(game.game_id)">PLAY</button>
                    <button
                        v-if="isTryModeEnabled"
                        class="btnTry"
                        @click="() => gamePlayStore.playGame(game.game_id, true)"
                    >
                        TRY
                    </button>
                </div>
            </div>
        </div>
        <div class="gamename">
            <h3>{{ game.name }}</h3>
            <p class="provider">{{ game.product }}</p>
        </div>
    </div>
</template>

<style>
.btnInfo {
    position: absolute;
    width: 36px;
    height: 36px;
    top: 0px;
    right: 0px;
}

.btnStar {
    right: 41px !important;
}

.gamepreview {
    background: url(/images/imgplaceholder.jpg) no-repeat 50%;;
    width: 100%;
    position: relative;
    border: none;
    border-radius: 5px;
    overflow: hidden;
    flex-grow: 1;
    font-family: "BebasNeue", sans-serif;
    max-width: initial;
    aspect-ratio: 4/3;
    background-size: cover;
}
</style>
