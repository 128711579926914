import {defineStore} from "pinia"
import {computed, ref} from "vue"

import {useApi} from "@shared/composables/useApi"

export const usePromotionsStore = defineStore('PromotionsStore', () => {
    const {apiGet} = useApi()

    const DEFAULT_CACHE_DURATION = 1000 * 60 * 5; // 5 minutes

    const banners = ref([])
    const promotions = ref([])
    const newPromotions = ref([])

    const bannersLastFetched = ref() // Time of the last banners fetch
    const newPromotionsLastFetched = ref() // Time of the last new promotions fetch

    const features = [
        {
            "id": 1,
            "heading":"10% WAGER FREE Cashback Bonus",
            "description":"Playlive's Cash-back bonuses refund players 10% of their net losses over the previous week.<br/>To qualify, there is no opt-in required, Weekly Cash-back Bonuses are available to all our players. To qualify for your cash-back bonus, we have a few rules that need to be adhered to.",
            "link":"https://playlive.co.za/news/playlive-new-10-percent-no-wagering-weekly-cashback-bonus"
        },
        {
            "id": 2,
            "heading":"Same Day Payouts",
            "description":"Before the daily 1pm cut off, you may cancel your withdrawal and continue playing at any point and withdraw again, as long as its before 1pm you will receive your funds the same day.",
            "link":"https://playlive.co.za/news/same-day-payouts"
        },
        {
            "id": 3,
            "heading":"Cash-drop Friday",
            "description":"Every Friday between 8pm and 10pm 20 lucky players will receive a R1,000 cash bonus!",
            "link":"https://playlive.co.za/news/cash-drop-fridays"
        }
    ]

    const filterBanners = computed(() => (exclude) => {
        return banners.value.filter(banner => !banner.exclude.includes(exclude))
    })

    const isDataStale = (lastFetched, cacheDuration = DEFAULT_CACHE_DURATION) => {
        return lastFetched ? (Date.now() - lastFetched) > cacheDuration : true
    }

    const fetchBanners = async (forceRefresh = false) => {
        if (!forceRefresh && banners.value && !isDataStale(bannersLastFetched.value)) {
            return banners.value;
        }

        try {
            const {data} = await apiGet('/api/promotionBanners')
            banners.value = data.promotionBanners
            bannersLastFetched.value = Date.now()
        } catch (e) {
            console.warn({e})
        }
    }

    const fetchPromotions = async (isAuthenticated) => {
        try {
            const {data} = isAuthenticated
                ? await apiGet('/api/promotionsAuthenticated')
                : await apiGet('/api/promotions')
            promotions.value = data.promotions
        } catch (e) {
            promotions.value = []
            console.warn({e})
        }
    }

    const fetchNewPromotions = async (forceRefresh = false) => {
        if (!forceRefresh && newPromotions.value && !isDataStale(newPromotionsLastFetched.value)) {
            return newPromotions.value
        }

        try {
            const {data} = await apiGet('/api/newpromotions')
            newPromotions.value = data.promotions
            newPromotionsLastFetched.value = Date.now()
        } catch (e) {
            console.warn({e})
        }
    }

    return {
        banners,
        promotions,
        newPromotions,
        features,
        filterBanners,
        fetchBanners,
        fetchPromotions,
        fetchNewPromotions
    }
}, {
    persist: [
        {
            paths: [
                'banners',
                'promotions',
                'newPromotions',
                'features'
            ]
        }
    ]
})
