import {defineStore} from "pinia"
import {computed, ref} from "vue"

import {useApi} from "@shared/composables/useApi"

const LOYALTY_TIER_BRONZE = "Bronze"
const LOYALTY_TIER_SILVER = "Silver"
const LOYALTY_TIER_GOLD = "Gold"
const LOYALTY_TIER_PLATINUM = "Platinum"
const LOYALTY_TIER_BLACK = "Black"

export const useLoyaltyChestStore = defineStore('LoyaltyChestStore', () => {

    const {apiGet, apiPost} = useApi()

    const tierMapping = {
        [LOYALTY_TIER_BRONZE]: 'bronze',
        [LOYALTY_TIER_SILVER]: 'silver',
        [LOYALTY_TIER_GOLD]: 'gold',
        [LOYALTY_TIER_PLATINUM]: 'platinum',
        [LOYALTY_TIER_BLACK]: 'black',
    }

    const isLoyaltyChestModalVisible = ref(false)
    const loyaltyChest = ref(null)

    const isLoyaltyEnabled = computed(() => {
        return loyaltyChest.value?.loyaltyReward.status === 'active'
    })

    const getBalance = computed(() => loyaltyChest.value?.balance || 0)

    const getDiamondsAmount = computed(() => (tier) => {
        const tierKey = tierMapping[tier]
        const loyaltyReward = loyaltyChest.value?.loyaltyReward
        if (loyaltyReward && tierKey) {
            return ((loyaltyReward[`${tierKey}_diamonds`] - loyaltyReward[`${tierKey}_diamonds_used`]) % 10)
        }

        return 0
    })

    const getChestsAmount = computed(() => (tier) => {
        const loyaltyReward = loyaltyChest.value?.loyaltyReward
        if (loyaltyReward) {
            if(tier === 'black') {
                return Math.floor((loyaltyReward[`${tier}_diamonds`] - loyaltyReward[`${tier}_diamonds_used`]) / 10)
            }
            return (loyaltyReward[`${tier}_diamonds_used`] === 100) ? 0 : Math.floor((loyaltyReward[`${tier}_diamonds`] - loyaltyReward[`${tier}_diamonds_used`]) / 10)
        }
        return 0
    })

    const isChestAvailable = computed(() => {
        let isAvailable = false
        Object.values(tierMapping).forEach((tier) => {
            if (getChestsAmount.value(tier) > 0) {
                isAvailable = true
            }
        })
        return isAvailable
    })

    const getTierChests = computed(() => (tier) => {
        let tierChests = {
            chests: 0,
            used: 0,
        }

        const loyaltyReward = loyaltyChest.value?.loyaltyReward
        const tierKey = tierMapping[tier]

        if (loyaltyReward && tierKey) {
            tierChests.chests = Math.floor((loyaltyReward[`${tierKey}_diamonds`] - loyaltyReward[`${tierKey}_diamonds_used`]) / 10)
            tierChests.used = Math.floor(loyaltyReward[`${tierKey}_diamonds_used`] / 10)
        }

        return tierChests
    })

    const getProgress = computed(() => loyaltyChest.value?.progress || 0)
    const getTier = computed(() => loyaltyChest.value?.loyaltyReward?.current_tier || '')

    const isAllChestsUsed = computed(() => (tier) => {
        const loyaltyReward = loyaltyChest.value?.loyaltyReward
        if (loyaltyReward) {
            if(tier === 'black' && loyaltyReward.current_tier === 'Black') {
                return false
            }
            return (loyaltyReward[`${tier}_diamonds_used`] === 100)
        }
        return false
    })

    const getLoyaltyAmounts = computed(() => (tier) => {
        const tierAmounts = {
            [LOYALTY_TIER_BRONZE]: [0, 10, 20, 30, 40],
            [LOYALTY_TIER_SILVER]: [0, 50, 100, 150, 200],
            [LOYALTY_TIER_GOLD]: [0, 100, 200, 300, 400],
            [LOYALTY_TIER_PLATINUM]: [0, 250, 500, 750, 1000],
            [LOYALTY_TIER_BLACK]: [0, 500, 1000, 1500, 2000],
        }

        return tierAmounts[tier]
    })

    const setLoyaltyChestModalVisible = async (value) => {
        if (value) {
            await fetchLoyaltyChest()
        }

        isLoyaltyChestModalVisible.value = value
    }

    const postLoyaltyAmount = async (payload) => {
        try {
            const {data} = await apiPost('/api/loyaltyChests', payload)
            loyaltyChest.value.balance = data.balance
        } catch (e) {
            console.warn(e)
        }
    }

    const fetchLoyaltyChest = async () => {
        try {
            const {data} = await apiGet('/api/loyaltyChests')
            loyaltyChest.value = data

            loyaltyChest.value.progress *= 100
        } catch (e) {
            console.warn({e})
        }
    }

    const setLoyaltyProgress = ({ progress = 0, newDiamond = false, newChest = false }) => {
        loyaltyChest.value.progress = progress


        if (newDiamond || newChest) {
            fetchLoyaltyChest()
        }
    }

    return {
        getBalance,
        getDiamondsAmount,
        getChestsAmount,
        getLoyaltyAmounts,
        getProgress,
        getTier,
        getTierChests,
        isChestAvailable,
        isLoyaltyEnabled,
        isAllChestsUsed,
        fetchLoyaltyChest,
        isLoyaltyChestModalVisible,
        loyaltyChest,
        postLoyaltyAmount,
        setLoyaltyChestModalVisible,
        setLoyaltyProgress,
        tierMapping,
    }
})
