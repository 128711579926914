import '@shared/scss/main.scss'

import { createGtm } from '@gtm-support/vue-gtm'
import * as Sentry from "@sentry/vue"
import axios from 'axios'
import {createPinia} from "pinia"
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createApp } from 'vue'
import VueLazyLoad from 'vue3-lazyload'

import App from '@shared/App.vue'
import useSharedRouter from "@shared/router";
import {globalPropertiesPlugin} from "@shared/globalPropertiesPlugin";

const csrfTokenMeta = document.querySelector("meta[name='csrf-token']")
if(csrfTokenMeta) {
    axios.defaults.headers.common['x-csrf-token'] = csrfTokenMeta.getAttribute('content')
}

const bearer = localStorage.getItem('play_live_session_token')
if(bearer) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${bearer}`
}

const router = useSharedRouter([
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/HomePage.vue'),
    },
    {
        path: '/how-to',
        name: 'how-to',
        component: () => import('@/views/HowToPage.vue'),
    },
    {
        path: '/about-us',
        name: 'about-us',
        component: () => import('@/views/AboutUsPage.vue'),
    },
    {
        path: '/promotions',
        name: 'promotions',
        component: () => import('@/views/PromotionsPage.vue'),
    },
])
const pinia = createPinia()
const app = createApp(App)

pinia.use(piniaPluginPersistedstate)

app.use(globalPropertiesPlugin)
app.use(VueLazyLoad)
app.use(pinia)
app.use(createGtm({
    id: "GTM-MTPTTHS2",
    vueRouter: router,
    debug: true
}))

Sentry.init({
    app,
    dsn: "https://418ae17eef914e2a655a1b89569f0f49@sentry.stackq.com/275",
    environment: window.env,
    release: window.appVersion,
    integrations: [],
    tracesSampleRate: 0.0001,
    replaysSessionSampleRate: 0.0001,
    replaysOnErrorSampleRate: 1.0,
})

app.use(router)
app.mount('#app')
