<script setup>
import {computed} from "vue";

const props = defineProps({
    url: String,
    sizes: Object,
    alt: String,
})

const isResponsiveEnabled = computed(() => {
    return props.sizes
        && Object.keys(props.sizes).length > 0
        && window.env !== 'local'
})

const getExtension = (url) => {
    if (url && url.includes('.')) {
        return url.split('.').pop()
    }
    return 'jpg' // default extension
}

const updateUrl = (url, newDomain) => {
    if (!url) {
        return url
    }

    try {
        const urlObject = new URL(url)
        urlObject.pathname = `/cdn/images${urlObject.pathname}`
        urlObject.hostname = newDomain
        return urlObject
            .toString()
            .replace(/%20/g, '%2520')
    } catch (e) {
        return url
    }
}

const generateImageUrl = (size, extension) => {
    const originalExtension = getExtension(props.url)
    const updatedUrl = updateUrl(props.url, window.location.host)
    if (!updatedUrl) {
        return props.url
    }
    if (size) {
        return updatedUrl.replace(`.${originalExtension}`, `-w_${size}.${extension}`)
    }
    // Use the smallest size from the provided sizes as the initial image
    const smallestSize = Math.min(...Object.values(props.sizes).map((item) => item.size).map(Number))
    return updatedUrl.replace(`.${originalExtension}`, `-w_${smallestSize}.${extension}`)
}
</script>

<template>
    <picture v-if="isResponsiveEnabled" style="height: auto !important;">
        <source
            v-for="({size, mediaQuery}) in sizes"
            :key="`webp-${size}`"
            :srcset="generateImageUrl(size, 'webp')"
            :media="mediaQuery"
            :type="`image/webp`"
        />
        <source
            v-for="({size, mediaQuery}) in sizes"
            :key="`${getExtension(props.url)}-${size}`"
            :srcset="generateImageUrl(size, getExtension(props.url))"
            :media="mediaQuery"
            :type="`image/${getExtension(props.url)}`"
        />
        <img
            :src="generateImageUrl(null, getExtension(props.url))"
            :alt="alt"
            loading="lazy"
            v-bind="$attrs"
        />
    </picture>
    <img
        v-else
        :src="props.url"
        :alt="alt"
        loading="lazy"
        v-bind="$attrs"
    />
</template>
