import axios from "axios"
import {defineStore} from "pinia"
import {ref} from "vue"

import {useAccountStore} from "@shared/stores/AccountStore"
import {useAuthStore} from "@shared/stores/AuthStore"
import {useLoyaltyChestStore} from "@shared/stores/LoyaltyChestStore"
import {useNotificationsStore} from "@shared/stores/NotificationsStore"
import {useTournamentStore} from "@shared/stores/TournamentStore"
import {useWalletStore} from "@shared/stores/WalletStore"

export const useGamePlayStore = defineStore('GamePlayStore', () => {

    const accountStore = useAccountStore()
    const authStore = useAuthStore()
    const notificationsStore = useNotificationsStore()
    const loyaltyChestStore = useLoyaltyChestStore()
    const walletStore = useWalletStore()
    const tournamentStore = useTournamentStore()

    const currentGameId = ref('')
    const game = ref({})
    const gameUrl = ref('')
    const isDemoMode = ref(false)
    const tournamentId = ref(null)
    const playGameAttempt = ref(null)

    const setChatVisibility = (isVisible) => {
        const chat = document.getElementById('chat-widget-container')
        if(chat) {
            chat.style.display = isVisible ? 'block' : 'none'
        }
    }

    const fetchGameUrl = async () => {
        try {
            document.body.style.overflow = 'hidden'
            setChatVisibility(false)

            const {data} = await axios.get(`/api/games/${currentGameId.value}/${isDemoMode.value}`)
            gameUrl.value = data.gameUrl

            game.value = {
                ...data.game,
                product: data.game.product.replace('EAGaming', 'Eurasian Gaming')
            }

            loyaltyChestStore.fetchLoyaltyChest()
            tournamentId.value = data.tournamentId


            if(tournamentId.value) {
                tournamentStore.fetchTournament(tournamentId.value)
                tournamentStore.watchTournamentProgress(tournamentId.value)
            }
        } catch ({ response }) {
            stopCurrentGame()
            notificationsStore.showAlert(response.data.error)
        }
    }

    const playGame = (gameId, isDemo = false) => {
        if(!isDemo && !accountStore.isAuthenticated) {
            playGameAttempt.value = { gameId, isDemo }
            authStore.setAuthModalVisibility(true, 'login')
            return
        }
        playGameAttempt.value = null
        currentGameId.value = gameId
        isDemoMode.value = isDemo
        fetchGameUrl()
    }

    const stopCurrentGame = () => {
        currentGameId.value = ''
        isDemoMode.value = false
        gameUrl.value = ''
        game.value = {}

        document.body.style.overflow = 'auto'
        setChatVisibility(true)

        walletStore.fetchWallet({
            isSilent: true
        })
    }

    return {
        currentGameId,
        isDemoMode,
        gameUrl,
        game,
        playGame,
        stopCurrentGame,
        tournamentId,
        playGameAttempt
    }
})
