import axios from "axios"
import {defineStore} from "pinia"
import {ref} from "vue"

export const useProvidersStore = defineStore('ProvidersStore', () => {

    const providers = ref({})
    const isProvidersFetched = ref(false)

    const getProviders = () => {
        if(!isProvidersFetched.value) {
            axios.get('/api/gameProviders')
                .then(({ data }) => {
                    data.sort((a, b) => a.shortname.localeCompare(b.shortname))
                    providers.value = data
                    isProvidersFetched.value = true
                })
        }
    }

    return {
        providers,
        getProviders
    }
}, {
    persist: [
        {
            paths: [
                'providers'
            ]
        }
    ]
})
