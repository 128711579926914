import {formatDate} from "date-fns"
import {defineStore} from "pinia"
import {computed, ref} from "vue"

import {useApi} from "@shared/composables/useApi"
import useHelpers from "@shared/composables/useHelpers"
import useSocket from "@shared/composables/useSocket"
import {useAccountStore} from "@shared/stores/AccountStore"
import {useNotificationsStore} from "@shared/stores/NotificationsStore"
import {useWalletStore} from "@shared/stores/WalletStore"

const {formatSeconds} = useHelpers()

const COLOR_COMING_UP = "#9c13b0"
const COLOR_COMPLETED = "rgba(255, 255, 255, 0.25)"
const COLOR_LATE_REG = "#ff0000"
const COLOR_REGISTERING = "#0cd35d"
const COLOR_RUNNING = "#5555ff"

const TYPE_COMING_UP = "Coming Up"
const TYPE_COMPLETED = "Completed"
const TYPE_LATE_REG = "Late Reg."
const TYPE_REGISTERING = "Registering"
const TYPE_RUNNING = "Running"

export const useTournamentStore = defineStore('TournamentStore', () => {

    const {apiGet, apiPost, apiPut} = useApi()
    const {connect, disconnect} = useSocket()

    const accountStore = useAccountStore()
    const notificationsStore = useNotificationsStore()
    const walletStore = useWalletStore()

    const isTournamentProgressWatching = ref(false)
    const isTournamentModalVisible = ref(false)
    const tournament = ref(null)
    const tournaments = ref(null)
    const leaderboards = ref(null)

    const setTournamentModalVisibility = (visible) => {
        isTournamentModalVisible.value = visible
    }

    const getStageDetails = (_tournament) => {
        const now = new Date().getTime()
        const tournamentEnd = new Date(_tournament.tournament_end).getTime()

        if (tournamentEnd < now) {
            return {
                type: TYPE_COMPLETED,
                color: COLOR_COMPLETED,
                status: 'Ended @',
                info: formatDate(tournamentEnd, 'HH:mm')
            }
        }

        let tournamentStart = new Date(_tournament.tournament_start).getTime()
        let registrationEnd = new Date(_tournament.registration_end).getTime()

        if (tournamentStart < now) {
            if (registrationEnd > now) {
                if (_tournament.buy_in_duration && _tournament.buy_in_duration <=_tournament.completes_in / 60
                ) {
                    let endTime = (registrationEnd - now) / 1000

                    if (tournamentEnd === registrationEnd) {
                        endTime -= _tournament.buy_in_duration * 60
                    }

                    return {
                        type: TYPE_LATE_REG,
                        color: COLOR_LATE_REG,
                        status: 'Closes in',
                        info: formatSeconds(endTime)

                    }
                } else if (!_tournament.buy_in_duration) {
                    return {
                        type: TYPE_LATE_REG,
                        color: COLOR_LATE_REG,
                        status: 'Closes in',
                        info: formatSeconds((registrationEnd - now) / 1000)
                    }
                }
            }

            return {
                type: TYPE_RUNNING,
                color: COLOR_RUNNING,
                status: 'Players',
                info: _tournament.users_count || getUniquePlayers(_tournament).length
            }
        }

        let registrationStart = new Date(_tournament.registration_start).getTime()

        if (registrationStart > now) {
            return {
                type: TYPE_COMING_UP,
                color: COLOR_COMING_UP,
                status: 'Reg. starts in',
                info: formatSeconds((registrationStart - now) / 1000)
            }
        }

        return {
            type: TYPE_REGISTERING,
            color: COLOR_REGISTERING,
            status: 'Starts in',
            info: formatSeconds((tournamentStart - now) / 1000)
        }
    }

    const isRegistrationOpen = computed(() => {
        const tournamentStage = getStageDetails(tournament.value)
        return tournamentStage.type === TYPE_LATE_REG || tournamentStage.type === TYPE_REGISTERING
    })

    const isTournamentRunning = computed(() => {
        const type = getStageDetails(tournament.value).type
        return type === TYPE_LATE_REG || type === TYPE_RUNNING
    })

    const getLastPlayerBuyIn = computed(() => {
        const myAttempts = tournament.value.tournament_users
            .filter(u => u.user_id === accountStore.user.id)
            .sort((a, b) => b.time_played - a.time_played)

        const runningAttempt = myAttempts.find(attempt => getTimeRemaining(attempt))
        return runningAttempt ?? myAttempts[0] ?? {}
    })

    const getCurrentPosition = computed(() => {
        const uniqueUsers = getUniquePlayers(tournament.value)
        const tournamentUser = getLastPlayerBuyIn.value

        if (tournamentUser?.position) {
            return tournamentUser.position
        }

        const userPosition = uniqueUsers.findIndex(user => parseFloat(tournamentUser.points) > parseFloat(user.points))

        return userPosition !== -1 ? uniqueUsers[userPosition].position : uniqueUsers.length
    })

    const getTimeRemaining = (_playerBuyIn) => {
        const {completes_in, buy_in_duration} = tournament.value
        const {time_played, started_at} = _playerBuyIn

        if (!started_at) {
            return 0
        }

        const timeRemaining = buy_in_duration
            ? buy_in_duration * 60 + (time_played || 0)
            : completes_in || 0

        return Math.max(0, timeRemaining)
    }

    const getTournamentProgress = computed(() => {
        if(!tournament.value) {
            return {
                points: 0,
                position: 0,
                playerCount: 0,
                started: false,
                timeRemaining: 0,
            }
        }

        const lastBuyIn = getLastPlayerBuyIn.value

        console.log({
            lastBuyIn
        })

        return {
            points: lastBuyIn?.points,
            position: getCurrentPosition.value,
            playerCount: getUniquePlayers(tournament.value).length,
            started: !!lastBuyIn?.started_at,
            timeRemaining: getTimeRemaining(lastBuyIn),
        }
    })

    const isCurrentTournament = computed(() => {
        let lastBuyIn = getLastPlayerBuyIn.value
        let timeLeft = lastBuyIn.time_played + tournament.value.buy_in_duration * 60
        return lastBuyIn && (!tournament.value.buy_in_duration || timeLeft > 0)
    })

    const getUniquePlayers = (_tournament) => {
        return [..._tournament?.tournament_users || []]
            .sort((a, b) => a.position - b.position)
            .filter(user => user.position !== 0)
    }

    const fetchTournament = async (id, options = {}) => {
        const {data} = await apiGet(`/api/tournaments/${id}`, options)
        tournament.value = data.tournament
    }

    const fetchStartTournament = (gameId) => {
        return new Promise(resolve => {
            apiPut(`/api/tournaments/${tournament.value.id}/${gameId}`)
                .then(({data}) => {
                    tournament.value = data.tournament
                    resolve()
                })
                .catch(e => {
                    notificationsStore.showAlert(e)
                })
        })
    }

    const fetchJoinTournament = () => {
        return new Promise((resolve, reject) => {
            apiPost(`/api/tournaments/${tournament.value.id}`)
                .then(({data}) => {
                    tournament.value = data.tournament

                    if (tournament.value.buy_in) {
                        walletStore.fetchWallet({
                            isSilent: true
                        })
                    }

                    resolve()
                })
                .catch(e => {
                    console.error(e)
                    reject(e)
                })
        })
    }

    const refetchCurrentTournament = async () => {
        if (tournament.value) {
            await fetchTournament(tournament.value.id, {isSilent: true})
        }
    }

    const fetchTournamentsAndLeaderboards = async (options = {}) => {
        try {
            const {data} = await apiGet(`/api/tournaments`, options)
            tournaments.value = data.tournaments
            leaderboards.value = data.leaderboards
        } catch (e) {
            tournaments.value = []
            leaderboards.value = []
        }
    }

    const stopWatchingTournamentProgress = () => {
        if(tournament.value) {
            disconnect(`tournament.${tournament.value.id}`)
            console.log(`Socket channel: tournament.${tournament.value.id} disconnected!`)
        }
        if(isTournamentProgressWatching.value)  {
            isTournamentProgressWatching.value = false
        }
    }

    const fetchTournamentProgress = async (options = {}) => {
        const {data} = await apiGet(`/api/tournamentProgress/${tournament.value.id}`, options)
        tournament.value = data.tournament
    }

    const watchTournamentProgress = (tournamentId) => {
        disconnect(`tournament.${tournamentId}`)

        isTournamentProgressWatching.value = true

        console.log(`Socket channel: tournament.${tournament.value.id} connected!`)
        connect(`tournament.${tournamentId}`, ({ refresh }) => {
            if (refresh) {
                console.log({refresh, message: 'Fetching tournament progress..'})
                fetchTournamentProgress({ isSilent: true})
            }
        })
    }

    return {
        fetchTournament,
        fetchTournamentProgress,
        fetchJoinTournament,
        fetchStartTournament,
        fetchTournamentsAndLeaderboards,
        getStageDetails,
        getTournamentProgress,
        getUniquePlayers,
        isCurrentTournament,
        isRegistrationOpen,
        isTournamentModalVisible,
        isTournamentRunning,
        isTournamentProgressWatching,
        refetchCurrentTournament,
        setTournamentModalVisibility,
        tournament,
        tournaments,
        leaderboards,
        watchTournamentProgress,
        stopWatchingTournamentProgress
    }
})
