<script setup>
import {useAccountStore} from "@shared/stores/AccountStore"
import HomeAuthenticated from "@shared/views/Home/HomeAuthenticated.vue"
import HomeGuest from "@shared/views/Home/HomeGuest.vue"

const accountStore = useAccountStore()
</script>

<template>
    <HomeAuthenticated v-if="accountStore.isAuthenticated"/>
    <HomeGuest v-else/>
</template>
