<script setup>

import MainLayout from "@shared/layouts/MainLayout.vue"
import {useRoute} from "vue-router"

const route = useRoute()
</script>

<template>
    <router-view v-if="route.name === 'unavailable'" />

    <MainLayout v-else>
        <router-view />
    </MainLayout>
</template>

<style>
    .textGreen {
        color: #0cd35d;
    }
    .textRed {
        color: red;
    }
</style>
