<script setup>
import {onMounted} from "vue"

import {useGameStore} from "@shared/stores/GameStore"

const authStore = useAuthStore()
const gameStore = useGameStore()

import GameList from "@shared/components/molecules/GameList.vue"
import {useAuthStore} from "@shared/stores/AuthStore"

onMounted(function () {
    gameStore.fetchGames(false, {isSilent: true})
})
</script>

<template>
    <div id="homePage">
        <div id="divImageSlider">
            <div class="imageSliderComponent">
                <div class="divImageScroller" style="left: 0%; width: 100%;">
                    <div class="divSliderItem" style="width: 100%;"></div>
                </div>
                <div class="btnPrevious"></div>
                <div class="btnNext"></div>
            </div>

            <div class="imageSliderComponent">
                <div class="divImageScroller" style="left: 0%; width: 100%;">
                    <div class="divSliderItem" style="width: 100%;">
                        <picture>
                            <template v-for="size in [320,640,768,1024,1366,1600]" :key="size">
                                <source :media="`(max-width: ${size}px)`" :srcset="`/images/pages/sliderImages/slide1@${size}.webp`" type="image/webp">
                                <source :media="`(max-width: ${size}px)`" :srcset="`/images/pages/sliderImages/slide1@${size}.jpg`" type="image/jpg">
                            </template>

                            <img class="imgSliderImage"
                                 src="/images/pages/sliderImages/slide1.jpg"
                                 alt="SOUTH AFRICA'S LARGEST ONLINE CASINO"
                                 style="aspect-ratio: 96/31"
                            >
                        </picture>
                        <h1 class="h1SliderCaption">SOUTH AFRICA'S LARGEST ONLINE CASINO</h1></div>
                </div>
                <div class="btnPrevious"></div>
                <div class="btnNext"></div>
            </div>
        </div>

        <div class="centerBtn">
            <button
                @click="authStore.setAuthModalVisibility(true, 'registration')"
                id="btnJoinNow"
            >
                JOIN NOW
            </button>
        </div>

        <div id="populargames" class="gamesblock" style="margin: 0; max-width: unset;">
            <div class="top">
                <h2 class="games-label">MOST POPULAR</h2>

            </div>
            <div id="form"></div>
            <div class="wrapper">
                <GameList
                    group="mostPopular"
                    :url="{name: 'webplay.most-popular'}"
                    :display-amounts="{xl: 15, md: 12, sm: 6}"
                />
            </div>
        </div>

        <div id="livecasinogames" class="gamesblock" style="margin: 0; max-width: unset;">
            <div class="top">
                <h2 class="games-label">LIVE CASINO</h2>
            </div>
            <div class="wrapper">
                <GameList
                    group="Lives"
                    :url="{name: 'webplay.live-casino'}"
                />
            </div>
        </div>

        <div id="topgames" class="gamesblock" style="margin: 0; max-width: unset;">
            <div class="top">
                <h2 class="games-label">TOP RATED</h2>
            </div>
            <div class="wrapper">
                <GameList
                    group="topRated"
                    :url="{name: 'webplay.top-games'}"
                />
            </div>
        </div>
    </div>
</template>
