export function useGameFilterProps() {
    return {
        filter: {
            type: String,
            default: ''
        },
        selectedProvider: {
            type: String,
            default: ''
        },
        sortBy: {
            type: String,
            default: ''
        }
    }
}
