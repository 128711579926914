<script setup>

import {computed, onMounted, reactive, watch} from "vue"
import {useRoute} from "vue-router"

import GamesNavigation from "@shared/components/molecules/GamesNavigation.vue"
import {useGameStore} from "@shared/stores/GameStore"
import {useProvidersStore} from "@shared/stores/ProvidersStore";
import GameProvidersSlider from "@shared/components/molecules/GameProvidersSlider.vue";

const providersStore = useProvidersStore()
const gameStore = useGameStore()
const route = useRoute()

const state = reactive({
    filter: '',
    selectedProvider: '',
    sortBy: '',
    isSearchBarHidden: false,
    isProviderDropdownHidden: false,
})

const providers = computed(() => providersStore.providers)

const checkAndToggleByRoute = () => {
    state.isSearchBarHidden = route.name === 'webplay.game-providers'
    state.isProviderDropdownHidden = route.name === 'webplay.provider'
}

const clearFilters = () => {
    state.filter = ''
    state.selectedProvider = ''
    state.sortBy = ''
}

onMounted(() => {
    gameStore.fetchGames()
    gameStore.fetchAllGames()
    checkAndToggleByRoute()
})

watch(
    () => route.name,
    () => {
        clearFilters()
        checkAndToggleByRoute()
    }
)
watch(
    () => route.params.category,
    () => {
        clearFilters()
    }
)

</script>

<template>
    <div id="webplay" :class="route.params.category">

        <div id="headerContainer">
            <GamesNavigation />
            <GameProvidersSlider />
        </div>

        <div class="wrapper">
            <div v-if="!state.isSearchBarHidden" id="filtersortsearchbar">
                <div class="options">
                    <div class="filter" :class="{ 'show': !state.isProviderDropdownHidden }">
                        <p>Provider:</p>
                        <div class="providerslist">
                            <select v-model="state.selectedProvider" name="providers" id="providersfilter">
                                <option value="" selected>All</option>
                                <option v-for="provider in providers" :key="provider.shortname" :value="provider.product">
                                    {{ provider.product }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="sort show">
                        <p>Sort by:</p>
                        <div class="sortinglist">
                            <select v-model="state.sortBy" name="sortoptions" id="sortoptions">
                                <option value="" disabled="" selected="">Select</option>
                                <option value="alphasc">A to Z</option>
                                <option value="alphdsc">Z to A</option>
                                <option value="newestf">Newest first</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="gamesearch">
                    <input type="password" style="display:none">
                    <input type="text" placeholder="Filter games" maxlength="40" v-model="state.filter">
                    <div
                        class="btnSearchInput fa fa-search"
                        :style="{
                            color: state.filter ? 'rgb(0, 136, 0)' : null
                        }"
                    />
                </div>
            </div>

            <router-view
                :filter="state.filter"
                :selectedProvider="state.selectedProvider"
                :sortBy="state.sortBy"
            />
        </div>
    </div>
</template>
