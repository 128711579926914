<script setup>
    import {useAccountStore} from "@shared/stores/AccountStore"
    import {useAuthStore} from "@shared/stores/AuthStore"
    import {useCouponStore} from "@shared/stores/CouponStore"
    import { useWalletStore } from '@shared/stores/WalletStore'
    import {reactive} from "vue"

    const accountStore = useAccountStore()
    const authStore = useAuthStore()
    const couponStore = useCouponStore()
    const walletStore = useWalletStore()

    const state = reactive({
        isOpen: false,
        isGamesOpen: false,
    })

    const handleCloseMenu = () => {
        state.isOpen = false
    }

    const handleRedeemCoupon = () => {
        handleCloseMenu()
        if (accountStore.isAuthenticated) {
            couponStore.setCouponModalVisibility(true)
        } else {
            authStore.setAuthModalVisibility(true, 'login')
        }
    }

    const handleCashierClick = () => {
        handleCloseMenu()
        if (accountStore.isAuthenticated) {
            walletStore.setWalletModalVisibility(true)
        } else {
            authStore.setAuthModalVisibility(true, 'login')
        }
    }

    const iconsFolder = '/images/navigationMenuIcons'

</script>

<template>
    <div id="divAppMenu" :class="{ 'menuOpen': state.isOpen }">
        <div id="divMenuOverlay" @click="state.isOpen = false"></div>
        <div id="divMenuHandel" @click="state.isOpen = !state.isOpen">
            <div></div>
        </div>
        <router-link @click="handleCloseMenu" :to="{name: 'home'}" id="imgMenuLogo">
            <img :src="`/images/jackpot-logo.svg`" alt="Jackpot Casino"/>
        </router-link>

        <div class="divMenuContainer">
            <nav id="mainnav">
                <router-link @click="handleCloseMenu" :to="{name: 'webplay.live-casino'}">
                    <img :src="`${iconsFolder}/main-live-games.svg`" class="navicon" alt="Live Games"/>
                    Live Games
                </router-link>

                <router-link @click="handleCloseMenu" :to="{name: 'webplay.category', params: {category: 'slots'}}">
                    <img :src="`${iconsFolder}/main-slots.svg`" class="navicon" alt="Slots"/>
                    Slots
                </router-link>

                <router-link @click="handleCloseMenu" :to="{name: 'webplay.all'}">
                    <img :src="`${iconsFolder}/main-games.svg`" class="navicon" alt="Games"/>
                    Games
                </router-link>

                <router-link @click="handleCloseMenu" :to="{name: 'promotions'}">
                    <img :src="`${iconsFolder}/main-promotions.svg`" class="navicon" alt="Promotions"/>
                    Promotions
                </router-link>

                <router-link @click="handleCloseMenu" :to="{name: 'tournaments'}">
                    <img :src="`${iconsFolder}/main-tournaments.svg`" class="navicon" alt="Tournaments"/>
                    Tournaments
                </router-link>

                <router-link @click="handleCloseMenu" :to="{name: 'how-to'}">
                    <img :src="`${iconsFolder}/main-how-to.svg`" class="navicon" alt="How To"/>
                    How To
                </router-link>

                <a class="cashier" @click="handleCashierClick">
                    <img :src="`${iconsFolder}/main-cashier.svg`" class="navicon" alt="Cashier"/>
                    Cashier
                </a>

                <a class="redeemtrigger" @click="handleRedeemCoupon">
                    <img :src="`${iconsFolder}/main-redeem.svg`" class="navicon" alt="Redeem"/>
                    Redeem
                </a>
            </nav>
        </div>
    </div>
</template>

<style scoped lang="scss">
@media screen and (min-width: 1200px) {
    .cashier {
        display: none !important;
    }
}
</style>
