import { ref } from 'vue'

import useHelpers from "@shared/composables/useHelpers"

export default function useSocket() {

    const { timeout } = useHelpers()
    const webSocket = ref(null)
    const channel = ref(null)

    const connect = async (_channel, _handler) => {
        const options = {
            key: window.ablyKey,
        }

        for (const time of [250, 500, 1000, 1500]) {
            if (typeof Ably === "undefined") {
                console.log(`Waiting for Ably: ${time}`)
                await timeout(time)
            }
            break
        }


        if (typeof Ably !== "undefined") {
            // eslint-disable-next-line no-undef
            webSocket.value = new Ably.Realtime(options)
            channel.value = webSocket.value.channels.get(_channel)
            // webSocket.value.connection.on("connected", function () {
            //     console.log("Ably connected")
            // })

            channel.value.subscribe((message) => {
                if (typeof message.data != "undefined") {
                    _handler(
                        message.data,
                        message.name
                    )
                }
            })
        }
    }

    const disconnect = () => {
        if (webSocket.value) {
            channel.value.unsubscribe()
            webSocket.value.close()
        }
    }

    return {
        webSocket,
        channel,
        connect,
        disconnect
    }
}
